<template>
    <section class="home-view">

        <!-- Hero banner -->
        <section class="hero-banner">
            <div class="hero-content">
                <div class="inner-wrapper">
                    <header class="hero-header">
                        <h2 class="super-title">Publications</h2>
                        <h1 class="title">Key figures on European transport</h1>
                        <p class="subtitle">2022 edition</p>
                    </header>

                    <article class="hero-info">
                        <div class="info-section">
                            <h3 class="section-title">Editors</h3>
                            <p class="authors">
                                <span>??</span>
                            </p>
                        </div>
                        <div class="info-section">
                            <h3 class="section-title">Layout and graphics</h3>
                            <p class="layout-graphics">
                                <span>Emporij d.o.o.</span>
                                <span class="extra"
                                    >Publications Office of the European Union,
                                    Graphic Design Department</span
                                >
                            </p>
                        </div>

                        <div class="social-share">
                            <p class="share-text">Share publication</p>
                            <div class="share-buttons">
                                <button class="share-button icon-only">
                                    <img
                                        src="/assets/svg/link-alt.svg"
                                        alt="Share on Link"
                                        width="24"
                                        height="24"
                                    />
                                </button>
                                <button class="share-button icon-only">
                                    <img
                                        src="/assets/svg/LinkedIn.svg"
                                        alt="Share on LinkedIn"
                                        width="24"
                                        height="24"
                                    />
                                </button>
                                <button class="share-button icon-only">
                                    <img
                                        src="/assets/svg/twitter.svg"
                                        alt="Share on X"
                                        width="24"
                                        height="24"
                                    />
                                </button>
                                <button class="share-button icon-only">
                                    <img
                                        src="/assets/svg/facebook.svg"
                                        alt="Share on Facebook"
                                        width="24"
                                        height="24"
                                    />
                                </button>
                            </div>
                        </div>
                    </article>

                    <nav class="hero-links">
                        <ul>
                            <li>
                                <a href="#" class="link-item">
                                    <img
                                        src="/assets/svg/plus.svg"
                                        alt="Add to my publications"
                                        width="24"
                                        height="24"
                                    />
                                    Add to my publications</a
                                >
                            </li>
                            <li>
                                <a href="#" class="link-item"
                                    ><img
                                        src="/assets/svg/bell-plus.svg"
                                        alt="Create alert"
                                        width="24"
                                        height="24"
                                    />Create alert</a
                                >
                            </li>
                            <li>
                                <a href="#" class="link-item"
                                    ><img
                                        src="/assets/svg/link.svg"
                                        alt="Permanent link"
                                        width="24"
                                        height="24"
                                    />Permanent link</a
                                >
                            </li>
                            <li>
                                <a href="#" class="link-item"
                                    ><img
                                        src="/assets/svg/purchase-tag-alt.svg"
                                        alt="Metadata RDF"
                                        width="24"
                                        height="24"
                                    />Metadata RDF</a
                                >
                            </li>
                            <li>
                                <a href="#" class="link-item"
                                    ><img
                                        src="/assets/svg/code.svg"
                                        alt="Embed"
                                        width="24"
                                        height="24"
                                    />Embed</a
                                >
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            <figure class="hero-image">
                <img
                    src="/assets/images/example_1.png"
                    role="presentation"
                    alt=""
                    width="654"
                    height="654"
                    loading="lazy"
                />
            </figure>
        </section>

        <!-- Contents -->
        <div class="contents">

            <!-- Text block -->
            <section class="frame-108">
                <div class="subtitle-51">Foreword</div>
                <div class="frame-109">
                    <div class="frame-110">
                        <div class="frame-111">
                            <img
                                src="/assets/images/person.png"
                                loading="lazy"
                                width="336"
                                height="419"
                                alt=""
                                class="image-5"
                            />
                            <div class="frame-110">
                                <div
                                    class="error-95135bb8-bfe7-8a74-2899-e158644e4fe1"
                                >
                                    I am pleased to present this first edition
                                    of Key figures on European transport. It
                                    provides a selection of key transport
                                    indicators for the European Union (EU), its
                                    individual Member States and European Free
                                    Trade Association (EFTA) countries, drawing
                                    from the rich collection of data available
                                    at Eurostat.
                                </div>
                                <div
                                    class="error-95135bb8-bfe7-8a74-2899-e158644e4fe1"
                                >
                                    Transport statistics can be used not only to
                                    describe the transport of people and goods,
                                    but also to provide information about issues
                                    such as transport equipment, safety,
                                    infrastructure and the economy, as well as
                                    transport’s environmental impact. Transport
                                    is critical to European businesses and
                                    global supply chains as well as to
                                    passengers. The transport sector contributes
                                    around 5 % to the EU’s gross domestic
                                    product and employs more than 6 million
                                    people. However, transport emissions
                                    represent around 25 % of the EU’s total
                                    greenhouse gas emissions. Transport’s
                                    importance has placed it at the heart of the
                                    European Green.
                                </div>
                            </div>
                        </div>
                        <div class="frame-110">
                            <div
                                class="error-95135bb8-bfe7-8a74-2899-e158644e4fe1"
                            >
                                Deal initiatives which strive for a cleaner,
                                greener and smarter mobility. Key figures on
                                European transport aims to provide intuitive
                                visualisations and innovative data presentations
                                supported by concise texts, in line with the
                                Eurostat publication Key figures on Europe. It
                                has been conceived to offer a balanced set of
                                indicators. The first two chapters start with a
                                presentation of transport measurement, providing
                                information on the movement of people and goods
                                by land, water and air transport modes. The
                                third chapter looks at transport safety, again
                                presenting information for various transport
                                modes. The fourth chapter combines information
                                on transport, the environment and energy. The
                                final chapter looks at a range of economic
                                indicators, such as employment in the transport
                                sector, transport prices, and expenditure on
                                transport. The COVID-19 pandemic and related
                                restrictions have mpacted on almost every aspect
                                of life in the EU (and further afield) since
                                March 2020. The pandemic itself and accompanying
                                restrictions have affected the supply of and
                                demand for many transport services within the
                                EU.
                            </div>
                            <div
                                class="error-95135bb8-bfe7-8a74-2899-e158644e4fe1"
                            >
                                Most of the data in this publication are
                                available up to 2020 or 2021 and show the impact
                                of the pandemic in these years. Eurostat’s most
                                upto- date statistics showing the economic and
                                social mpacts of the COVID-19 crisis can be
                                found online at:
                            </div>
                            <div class="subtitle-52">
                                <div class="subtitle-52 subtitle-52">
                                    <span
                                        class="error-95135bb8-bfe7-8a74-2899-e158644e4fe1"
                                    >
                                    </span
                                    ><span class="subtitle-54"
                                        >https://ec.europa.eu/eurostat/web/covid-19/overview.</span
                                    ><span
                                        class="error-95135bb8-bfe7-8a74-2899-e158644e4fe1"
                                    >
                                        Eurostat’s wide range of statistical
                                        information on transport can be accessed
                                        through our website. The latest and most
                                        comprehensive data available on the EU,
                                        its Member States, the EFTA countries,
                                        as well as candidate countries and
                                        potential candidates are available
                                        through our online database, while
                                        analysis is provided through a range of
                                        online articles in Statistics Explained.
                                        I hope that you find this publication
                                        interesting and useful.</span
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="frame-112">
                        <img
                            src="/assets/images/signature.png"
                            loading="lazy"
                            width="383"
                            height="104"
                            alt=""
                            class="image-5"
                        />
                        <div class="frame-31">
                            <div class="subtitle-53">Viveka Palm</div>
                            <div
                                class="error-95135bb8-bfe7-8a74-2899-e158644e4fe1"
                            >
                                Director of sectoral and regional statistics,
                                Eurostat
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    </section>
</template>

<script>
export default {
    name: "ForewordView",
    props: {
        msg: String,
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/views/home.scss";
</style>
