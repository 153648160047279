<template>
    <section class="home-view">

        <!-- Hero banner -->
        <section class="hero-banner alt-banner">
            <div class="hero-content">
                <div class="inner-wrapper">

                    <header class="hero-header">
                        <h2 class="super-title">Publications</h2>
                                    
                        <figure class="hero-image">
                            <img
                                src="/assets/images/document_1.png"
                                role="presentation"
                                alt=""
                                width="654"
                                height="654"
                                loading="lazy"
                            />
                        </figure>
                    </header>

                    <article class="hero-info">
                        <h1 class="title">Key figures on European transport</h1>
                        <p class="subtitle">2022 edition</p>

                        <nav class="hero-links">
                            <ul>
                                <li>
                                    <a href="#" class="link-item">
                                        <img
                                            src="/assets/svg/plus.svg"
                                            alt="Add to my publications"
                                            width="24"
                                            height="24"
                                        />
                                        Add to my publications</a
                                    >
                                </li>
                                <li>
                                    <a href="#" class="link-item"
                                        ><img
                                            src="/assets/svg/bell-plus.svg"
                                            alt="Create alert"
                                            width="24"
                                            height="24"
                                        />Create alert</a
                                    >
                                </li>
                                <li>
                                    <a href="#" class="link-item"
                                        ><img
                                            src="/assets/svg/link.svg"
                                            alt="Permanent link"
                                            width="24"
                                            height="24"
                                        />Permanent link</a
                                    >
                                </li>
                                <li>
                                    <a href="#" class="link-item"
                                        ><img
                                            src="/assets/svg/purchase-tag-alt.svg"
                                            alt="Metadata RDF"
                                            width="24"
                                            height="24"
                                        />Metadata RDF</a
                                    >
                                </li>
                                <li>
                                    <a href="#" class="link-item"
                                        ><img
                                            src="/assets/svg/code.svg"
                                            alt="Embed"
                                            width="24"
                                            height="24"
                                        />Embed</a
                                    >
                                </li>
                            </ul>
                        </nav>
                        
                        <div class="description">
                            <p>Key figures on European transport presents a selection of key transport indicators for the European Union (EU) and its individual Member States, as well as the EFTA countries. This publication may be viewed as an introduction to European transport statistics and provides a starting point for those who wish to explore the wide range of data that are freely available on Eurostat's website at https://ec.europa.eu/eurostat together with a range of online articles in Statistics Explained. Key figures on European transport aims to provide intuitive visualisations and innovative data presentations supported by concise texts, in line with the Eurostat publication Key figures on Europe. It has been conceived to offer a balanced set of indicators. The first two chapters start with a presentation of transport measurement, providing information on the movement of people and goods by land, water and air transport modes. The third chapter looks at transport safety, again presenting information for various transport modes. The fourth chapter combines information on transport, the environment and energy. The final chapter looks at a range of economic indicators, such as employment in the transport sector, transport prices, and expenditure on transport.</p>
                        </div>

                        <div class="actions">
                            <button class="button">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M16.293 8.29297L12 12.586L7.70697 8.29297L6.29297 9.70697L12 15.414L17.707 9.70697L16.293 8.29297Z" fill="#123493"/></svg>
                                <span>How to cite</span>
                            </button>

                            <button class="button">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M5.99997 18H18V20H5.99997V18ZM11 4V12.586L6.70697 8.293L5.29297 9.707L12 16.414L18.707 9.707L17.293 8.293L13 12.586V4H11Z" fill="#123493"/></svg>
                                <span>Download and languages</span>
                            </button>
                        </div>
                    </article>
                </div>
            </div>
        </section>

        <!-- Contents -->
        <div class="contents">
            <!-- Text block -->
            <div class="frame-84">
                <div class="frame-81">
                    <div class="subtitle-76">Publication details</div>
                    <div class="frame-80">
                        <div class="frame-79">
                            <div class="text-3">Published: 2023</div>
                            <div class="text-3">
                                Corporate author(s): Eurostat (European
                                Commission)&nbsp;(<a
                                    href="https://op.europa.eu/en/publication-detail?p_p_id=publicationDetails_PublicationDetailsPortlet&amp;p_p_lifecycle=1&amp;p_p_state=normal&amp;p_p_mode=view&amp;_publicationDetails_PublicationDetailsPortlet_javax.portlet.action=author&amp;facet.author=COM,ECFIN,TASKF,OIL,OIB,REPRES_NLD,EPSO,REPRES_LVA,JLS,ERC,MARKT,MARE,REGIO,REA,BEPA,PRESS,BDS,ELARG,PMO,REPRES_LIT,AGRI,REPRES_SPA_BCN,SPP,ECHO,EAPH,REPRES_GBR_LON,REPRES_EST,FPI,REPRES_SPA_MAD,CASSTM,CNECT,DIGIT,HOME,ENER,REPRES_HUN,IEEA,EASME,COMP,REPRES_CZE,REPRES_BGR,SCR,REPRES_MLT,REPRES_PRT,REPRES_CYP,REPRES_HRV,CLIMA,EAHC,REPRES_SWE,REPRES_SVN,DEL_ACC,INFSO,EACI,ETHI,DG18,DG15,DG10,CHAFEA,REPRES_DEU_MUC,REPRES_POL_WAW,ESTAT,DEVCO,DGT,EPSC,GROW,SANTE,NEAR,FISMA,JUST,COM_CAB,SCAD,REPRES_GBR,REPRES_POL,TASKF_A50_UK,REPRES_SPA,REPRES_FRA,REPRES_ITA,ACSHHPW,PC_BUDG,IAB,RSB,PC_CONJ,COM_COLL,ACSH,EVHAC,PC_MTE,REPRES_DEU,REPRES_SVK,JUSTI,REPRES_DEU_BON,SCIC,REPRES_FRA_PAR,SJ,SG,REPRES_POL_WRO,OLAF,REPRES_DEU_BER,CCSS,FSU,REPRES_IRL,HR,REPRES_LUX,REPRES_FIN,TAXUD,COMMU,SANCO,ENTR,AUDIT,IGS,REPRES_ITA_MIL,MOVE,BUDG,REPRES_ROU,RTD,IAS,BTL,TENTEA,BTB,CMT_EMPL,DG01B,DG01A,REPRES_BEL,REPRES_GBR_CDF,ENV,DG23,DG17,DG07,DG03,DG02,DG01,PUBL,REPRES_AUT,INEA,EMPL,EAC,TRADE,TREN,REPRES_ITA_ROM,RELEX,AIDCO,REPRES_GRC,EACEA,REPRES_GBR_BEL,REPRES_FRA_MRS,REPRES_GBR_EDI,REPRES_DAN,JRC,DEV,SRSS,STECF,DPO,SAM_ADV,UKTF,REFORM,DG22,DG14,DG11,DEFIS,IDEA,COM_PRES,ERCOU,INTPA,SC_OLAF,CINEA,EISMEA,HADEA,HERA&amp;language=en&amp;facet.collection=EUPub"
                                    target="_blank"
                                    class="corporate-authors-eurostat-european-commission-1"
                                    >European Commission</a
                                >)
                            </div>
                            <div class="text-3">
                                Personal author(s): Corselli-Nordblad, Louise ;
                                &nbsp;Jere, Nina ; &nbsp;Ford-Alexandraki,
                                Evangelia ; &nbsp;Xenellis, Georgios<a
                                    href="https://op.europa.eu/en/publication-detail?p_p_id=publicationDetails_PublicationDetailsPortlet&amp;p_p_lifecycle=1&amp;p_p_state=normal&amp;p_p_mode=view&amp;_publicationDetails_PublicationDetailsPortlet_javax.portlet.action=author&amp;facet.author=agent.Corselli-Nordblad__+Louise&amp;language=en&amp;facet.collection=EUPub"
                                    target="_blank"
                                    class="corporate-authors-eurostat-european-commission-1"
                                    >Corselli-Nordblad, Louise</a
                                >&nbsp;;&nbsp;&nbsp;<a
                                    href="https://op.europa.eu/en/publication-detail?p_p_id=publicationDetails_PublicationDetailsPortlet&amp;p_p_lifecycle=1&amp;p_p_state=normal&amp;p_p_mode=view&amp;_publicationDetails_PublicationDetailsPortlet_javax.portlet.action=author&amp;facet.author=agent.Jere__+Nina&amp;language=en&amp;facet.collection=EUPub"
                                    target="_blank"
                                    class="corporate-authors-eurostat-european-commission-1"
                                    >Jere, Nina</a
                                >&nbsp;;&nbsp;&nbsp;<a
                                    href="https://op.europa.eu/en/publication-detail?p_p_id=publicationDetails_PublicationDetailsPortlet&amp;p_p_lifecycle=1&amp;p_p_state=normal&amp;p_p_mode=view&amp;_publicationDetails_PublicationDetailsPortlet_javax.portlet.action=author&amp;facet.author=agent.Ford-Alexandraki__+Evangelia&amp;language=en&amp;facet.collection=EUPub"
                                    target="_blank"
                                    class="corporate-authors-eurostat-european-commission-1"
                                    >Ford-Alexandraki, Evangelia</a
                                >&nbsp;;&nbsp;&nbsp;<a
                                    href="https://op.europa.eu/en/publication-detail?p_p_id=publicationDetails_PublicationDetailsPortlet&amp;p_p_lifecycle=1&amp;p_p_state=normal&amp;p_p_mode=view&amp;_publicationDetails_PublicationDetailsPortlet_javax.portlet.action=author&amp;facet.author=agent.Xenellis__+Georgios&amp;language=en&amp;facet.collection=EUPub"
                                    target="_blank"
                                    class="corporate-authors-eurostat-european-commission-1"
                                    >Xenellis, Georgios</a
                                >
                            </div>
                            <div class="text-3">
                                Themes: &nbsp;<a
                                    href="https://op.europa.eu/en/publication-detail?p_p_id=publicationDetails_PublicationDetailsPortlet&amp;p_p_lifecycle=1&amp;p_p_state=normal&amp;p_p_mode=view&amp;_publicationDetails_PublicationDetailsPortlet_javax.portlet.action=theme&amp;selectedThemeId=N07&amp;facet.theme=N07&amp;language=en&amp;domain=EUPub"
                                    target="_blank"
                                    class="corporate-authors-eurostat-european-commission-1"
                                    >Transport</a
                                >
                            </div>
                            <div class="text-3">
                                Subject:&nbsp;<a
                                    href="https://op.europa.eu/en/publication-detail?p_p_id=publicationDetails_PublicationDetailsPortlet&amp;p_p_lifecycle=1&amp;p_p_state=normal&amp;p_p_mode=view&amp;_publicationDetails_PublicationDetailsPortlet_javax.portlet.action=subject&amp;selectedSubjectId=5283&amp;facet.eurovoc.subject=5283&amp;language=en&amp;domain=EUPub&amp;domain=EULex&amp;domain=EUWebPage&amp;domain=EUSummariesOfLegislation"
                                    target="_blank"
                                    class="corporate-authors-eurostat-european-commission-1"
                                    >EU Member State</a
                                >,&nbsp;<a
                                    href="https://op.europa.eu/en/publication-detail?p_p_id=publicationDetails_PublicationDetailsPortlet&amp;p_p_lifecycle=1&amp;p_p_state=normal&amp;p_p_mode=view&amp;_publicationDetails_PublicationDetailsPortlet_javax.portlet.action=subject&amp;selectedSubjectId=4258&amp;facet.eurovoc.subject=4258&amp;language=en&amp;domain=EUPub&amp;domain=EULex&amp;domain=EUWebPage&amp;domain=EUSummariesOfLegislation"
                                    target="_blank"
                                    class="corporate-authors-eurostat-european-commission-1"
                                    >EU statistics&nbsp;</a
                                >,&nbsp;<a
                                    href="https://op.europa.eu/en/publication-detail?p_p_id=publicationDetails_PublicationDetailsPortlet&amp;p_p_lifecycle=1&amp;p_p_state=normal&amp;p_p_mode=view&amp;_publicationDetails_PublicationDetailsPortlet_javax.portlet.action=subject&amp;selectedSubjectId=1954&amp;facet.eurovoc.subject=1954&amp;language=en&amp;domain=EUPub&amp;domain=EULex&amp;domain=EUWebPage&amp;domain=EUSummariesOfLegislation"
                                    target="_blank"
                                    class="corporate-authors-eurostat-european-commission-1"
                                    >mode of transport&nbsp;</a
                                >,&nbsp;<a
                                    href="https://op.europa.eu/en/publication-detail?p_p_id=publicationDetails_PublicationDetailsPortlet&amp;p_p_lifecycle=1&amp;p_p_state=normal&amp;p_p_mode=view&amp;_publicationDetails_PublicationDetailsPortlet_javax.portlet.action=subject&amp;selectedSubjectId=3109&amp;facet.eurovoc.subject=3109&amp;language=en&amp;domain=EUPub&amp;domain=EULex&amp;domain=EUWebPage&amp;domain=EUSummariesOfLegislation"
                                    target="_blank"
                                    class="corporate-authors-eurostat-european-commission-1"
                                    >transport market&nbsp;</a
                                >,&nbsp;<a
                                    href="https://op.europa.eu/en/publication-detail?p_p_id=publicationDetails_PublicationDetailsPortlet&amp;p_p_lifecycle=1&amp;p_p_state=normal&amp;p_p_mode=view&amp;_publicationDetails_PublicationDetailsPortlet_javax.portlet.action=subject&amp;selectedSubjectId=5908&amp;facet.eurovoc.subject=5908&amp;language=en&amp;domain=EUPub&amp;domain=EULex&amp;domain=EUWebPage&amp;domain=EUSummariesOfLegislation"
                                    target="_blank"
                                    class="corporate-authors-eurostat-european-commission-1"
                                    >transport statistics</a
                                >
                            </div>
                        </div>
                        <div class="links-2">
                            <div class="content-24">
                                <img
                                    src="/assets/svg/chevron-down-alt.svg"
                                    loading="lazy"
                                    width="24"
                                    height="24"
                                    alt=""
                                    class="vectors-wrapper-39"
                                />
                                <div class="text-3">View more</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="frame-83">
                    <img
                        src="/assets/images/document.png"
                        loading="lazy"
                        width="606"
                        height="853"
                        alt=""
                        class="asset-1-2x-100-1"
                    />
                    <div class="frame-82">
                        <img
                            src="/assets/svg/chevron-right-circle.svg"
                            loading="lazy"
                            width="50.000003814697266"
                            height="50.000003814697266"
                            alt=""
                            class="vectors-wrapper-40"
                        /><img
                            src="/assets/svg/chevron-right-circle-alt.svg"
                            loading="lazy"
                            width="50"
                            height="50"
                            alt=""
                            class="vectors-wrapper-41"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "DocumentView",
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/views/home.scss";
</style>
