<template>
    <section class="home-view">

        <!-- Hero banner -->
        <section class="hero-banner">
            <div class="hero-content">
                <div class="inner-wrapper">
                    <header class="hero-header">
                        <h2 class="super-title">Publications</h2>
                        <h1 class="title">Key figures on European transport</h1>
                        <p class="subtitle">2022 edition</p>
                    </header>

                    <article class="hero-info">
                        <div class="info-section">
                            <h3 class="section-title">Editors</h3>
                            <p class="authors">
                                <span>??</span>
                            </p>
                        </div>
                        <div class="info-section">
                            <h3 class="section-title">Layout and graphics</h3>
                            <p class="layout-graphics">
                                <span>Emporij d.o.o.</span>
                                <span class="extra"
                                    >Publications Office of the European Union,
                                    Graphic Design Department</span
                                >
                            </p>
                        </div>

                        <div class="social-share">
                            <p class="share-text">Share publication</p>
                            <div class="share-buttons">
                                <button class="share-button icon-only">
                                    <img
                                        src="/assets/svg/link-alt.svg"
                                        alt="Share on Link"
                                        width="24"
                                        height="24"
                                    />
                                </button>
                                <button class="share-button icon-only">
                                    <img
                                        src="/assets/svg/LinkedIn.svg"
                                        alt="Share on LinkedIn"
                                        width="24"
                                        height="24"
                                    />
                                </button>
                                <button class="share-button icon-only">
                                    <img
                                        src="/assets/svg/twitter.svg"
                                        alt="Share on X"
                                        width="24"
                                        height="24"
                                    />
                                </button>
                                <button class="share-button icon-only">
                                    <img
                                        src="/assets/svg/facebook.svg"
                                        alt="Share on Facebook"
                                        width="24"
                                        height="24"
                                    />
                                </button>
                            </div>
                        </div>
                    </article>

                    <nav class="hero-links">
                        <ul>
                            <li>
                                <a href="#" class="link-item">
                                    <img
                                        src="/assets/svg/plus.svg"
                                        alt="Add to my publications"
                                        width="24"
                                        height="24"
                                    />
                                    Add to my publications</a
                                >
                            </li>
                            <li>
                                <a href="#" class="link-item"
                                    ><img
                                        src="/assets/svg/bell-plus.svg"
                                        alt="Create alert"
                                        width="24"
                                        height="24"
                                    />Create alert</a
                                >
                            </li>
                            <li>
                                <a href="#" class="link-item"
                                    ><img
                                        src="/assets/svg/link.svg"
                                        alt="Permanent link"
                                        width="24"
                                        height="24"
                                    />Permanent link</a
                                >
                            </li>
                            <li>
                                <a href="#" class="link-item"
                                    ><img
                                        src="/assets/svg/purchase-tag-alt.svg"
                                        alt="Metadata RDF"
                                        width="24"
                                        height="24"
                                    />Metadata RDF</a
                                >
                            </li>
                            <li>
                                <a href="#" class="link-item"
                                    ><img
                                        src="/assets/svg/code.svg"
                                        alt="Embed"
                                        width="24"
                                        height="24"
                                    />Embed</a
                                >
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            <figure class="hero-image">
                <img
                    src="/assets/images/example_1.png"
                    role="presentation"
                    alt=""
                    width="654"
                    height="654"
                    loading="lazy"
                />
            </figure>
        </section>

        <!-- Contents -->
        <div class="contents">



            <div class="frame-34" style="margin-top: 120px">
                <div class="subtitle-56">Transport and the economy</div>
                <img
                    src="/assets/images/transport.png"
                    loading="lazy"
                    style="max-width: 100%; height: auto"
                    width="1092"
                    height="1206"
                    alt=""
                    role="presentation"
                />
            </div>



            <div class="frame-57">
                <div class="frame-42">
                    <div class="subtitle-58">
                        In 2021, 6.0 million people in the EU worked in the
                        transport sector (see map on the previous page). Nine in
                        every ten (89.6 %) persons employed in transport worked
                        in land transport (such as road or rail), 4.7 % in water
                        transport (inland waterways or maritime) and 5.7 % in
                        air transport.
                    </div>
                    <div class="subtitle-58">
                        Among the EU Member States, the largest transport
                        workforces in 2021 were in France (842 000, 13.9 % of
                        the EU total), Poland (13.1 %), Germany (11.4 %), Spain
                        and Italy (both 10.4 %). The smallest transport
                        workforces were in Cyprus and Malta (both 0.1 % of the
                        EU total).
                    </div>
                    <div class="subtitle-58">
                        Land transport dominated the transport workforce in 2021
                        in all but one of the EU Member States, accounting for a
                        majority of transport workers. The share of land
                        transport generally ranged from 64.5 % in Greece to 98.9
                        % in Slovenia. Malta was the exception, as its land
                        transport share was 46.4 %. The combined share of water
                        and air transport was relatively high in Malta and
                        Luxembourg, where air transport had a large share, as
                        well as in Greece and Croatia, where water transport had
                        a large share.
                    </div>
                </div>
                <div class="frame-45">
                    <div class="frame-42">
                        <div class="subtitle-59">
                            Employment distribution by transport subsector in
                            the EU (%)
                        </div>
                        <img
                            src="/assets/images/trasp_1.png"
                            loading="lazy"
                            width="Auto"
                            height="500"
                            alt=""
                            class="image-8"
                        />
                    </div>
                    <div class="frame-42">
                        <div class="subtitle-60">
                            Employment distribution by transport subsector in
                            the Member States (%)
                        </div>
                        <div class="frame-42">
                            <img
                                src="/assets/images/trasp_2.png"
                                loading="lazy"
                                width="447"
                                height="1184"
                                alt=""
                                class="image-8"
                            />
                            <div class="frame-40">
                                <div class="subtitle-61">
                                    Source: Eurostat (online data code:
                                    lfsa_egan22d)
                                </div>
                                <div class="subtitle-61">
                                    Note: CY, females, low reliability.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="frame-50">
                    <div class="frame-48">
                        <div class="frame-42">
                            <div class="subtitle-60">
                                Distribution of employment in the transport
                                sector by sex (%, 2021)
                            </div>
                            <div class="subtitle-58">
                                In 2021, a large majority of the transport
                                sector’s workforce in all EU Member States was
                                male. The highest share of females was recorded
                                in Malta at 25.1 % and the lowest share was 9.7
                                % in Romania; the EU average was 17.1 %
                            </div>
                        </div>
                        <img
                            src="/assets/images/trasp_3.png"
                            loading="lazy"
                            width="467"
                            height="995"
                            alt=""
                            class="image-8"
                        />
                    </div>
                    <div class="frame-49">
                        <div class="frame-42">
                            <div class="subtitle-60">
                                Distribution of employment in the transport
                                subsectors by sex (%, EU, 2021)
                            </div>
                            <div class="subtitle-58">
                                There is a clear relation between the sectoral
                                structure of employment and the share of males/
                                females in the workforce. For the EU as a whole,
                                42.0 % of people working in air transport in
                                2021 were women, compared with 21.9 % for water
                                transport and 15.2 % for land transport.
                            </div>
                        </div>
                        <img
                            src="/assets/images/trasp_4.png"
                            loading="lazy"
                            width="466"
                            height="808"
                            alt=""
                            class="image-8"
                        />
                    </div>
                </div>
                <div class="frame-42">
                    <div class="subtitle-60">
                        Distribution of employment in the transport sector by
                        age (%, 2021)
                    </div>
                    <div class="frame-38">
                        <img
                            src="/assets/images/trasp_5.png"
                            loading="lazy"
                            width="1092"
                            height="1085"
                            alt=""
                            class="image-8"
                        />
                        <div class="frame-37">
                            <img
                                src="/assets/images/trasp_6.png"
                                loading="lazy"
                                width="141"
                                height="105"
                                alt=""
                                class="image-8"
                            />
                            <div class="subtitle-62">
                                Note: IE, HR, CY and SI, 15–29 years, low
                                reliability. MT, 50–64 years, low reliability.
                                Source: Eurostat (online data code:
                                lfsa_egan22d)
                            </div>
                        </div>
                    </div>
                </div>
                <div class="subtitle-58">
                    An analysis by age of the EU’s transport sector’s workforce
                    in 2021 shows that around half (51.0 %) were aged 30–49
                    years, with 36.9 % older (aged 50–64 years) and 12.1 %
                    younger (aged 15–29 years). The share for persons aged 30–49
                    years was similar to that for whole economy, but the share
                    of older workers was higher in the transport sector while
                    the share of younger workers was lower. In terms of age,
                    Malta was again an outlier. The share of younger workers in
                    its transport sector was 26.4 % in 2021, more than double
                    the EU average. The next highest shares for younger
                    transport workers were 20.0 % in Finland and 19.5 % in the
                    Netherlands. By contrast, more than half of the transport
                    sector’s workforce was aged 50–64 years in Estonia and
                    Cyprus, with shares above 40.0 % in 10 other EU Member
                    States.
                </div>
                <img
                    src="/assets/images/trasp_7.png"
                    loading="lazy"
                    width="1090"
                    height="1251"
                    alt=""
                    class="image-8"
                />
                <div class="frame-52">
                    <div class="subtitle-63">
                        Changes over time in consumer prices (deflation or
                        inflation) are also reflected in an index: in the EU
                        this is the harmonised index of consumer prices.
                    </div>
                </div>
                <div class="frame-54">
                    <div class="frame-42">
                        <div class="subtitle-60">
                            Annual price change for transport fuels and
                            lubricants (%, 2020 and 2021)
                        </div>
                        <div class="frame-42">
                            <div class="subtitle-58">
                                The start of the COVID-19 crisis in 2020
                                resulted in a fall in demand for energy
                                products, and this was reflected in a 9.7 % fall
                                in consumer prices for transport fuels and
                                lubricants in the EU in that year. Every EU
                                Member State recorded a fall in these prices in
                                2020.
                            </div>
                            <div class="subtitle-58">
                                Demand for energy products rebounded. In the EU,
                                consumer prices for transport fuels and
                                lubricants increased 17.2 % in 2021. Price
                                increases exceeded 20.0 % in Luxembourg,
                                Germany, Hungary and Poland. At the other end of
                                the range, Malta (-2.4 %) recorded the only fall
                                in consumer prices for transport fuels and
                                lubricants; the smallest increase elsewhere was
                                11.0 % in Ireland.
                            </div>
                        </div>
                    </div>
                    <div class="frame-42">
                        <img
                            src="/assets/images/trasp_8.png"
                            loading="lazy"
                            width="588"
                            height="788"
                            alt=""
                            class="image-8"
                        />
                        <div class="subtitle-62">
                            Note: MT, fuel prices are regulated Source: Eurostat
                            (online data code: prc_hicp_aind)
                        </div>
                    </div>
                </div>
                <div class="frame-54">
                    <div class="frame-42">
                        <div class="subtitle-60">
                            Annual price index for transport fuels and
                            lubricants (2011 = 100, EU, 2011–2021)
                        </div>
                        <div class="subtitle-58">
                            Looking over a longer period, from 2011 to 2021, the
                            EU’s annual consumer price index for transport fuels
                            and lubricants was relatively volatile, reflecting
                            changes in the underlying oil price. The price index
                            fell from a high in 2012 to a low in 2016 before
                            increasing in 2017 and 2018. The stability in 2019
                            was interrupted by the COVID-19 influenced fall in
                            2020 and rebound in 2021.
                        </div>
                    </div>
                    <div class="frame-42">
                        <img
                            src="/assets/images/trasp_9.png"
                            loading="lazy"
                            width="588"
                            height="477"
                            alt=""
                            class="vectors-wrapper-28"
                        />
                        <div class="subtitle-62">
                            Note: index rescaled from 2015 = 100. Source:
                            Eurostat (online data code: prc_hicp_aind)
                        </div>
                    </div>
                </div>
            </div>



            <div class="frame-71">
                <img
                    src="/assets/images/trasp_10.png"
                    loading="lazy"
                    width="1092"
                    height="681"
                    alt=""
                    class="image-8"
                />
                <div class="frame-67">
                    <div class="subtitle-65">
                        Development of share of household expenditure on
                        transport (%, EU, 2010–2020)
                    </div>
                    <div class="frame-59">
                        <div class="subtitle-66">
                            In 2019, the shares of household expenditure used
                            for the purchase of vehicles and for transport
                            services in the EU were higher than they had been in
                            2010, whereas the share for the operation of
                            personal transport equipment was the same. In 2020,
                            all three shares dropped, most notably for transport
                            services.
                        </div>
                        <img
                            src="/assets/images/trasp_11.png"
                            loading="lazy"
                            width="589"
                            height="368"
                            alt=""
                            class="image-8"
                        />
                    </div>
                </div>
                <div class="frame-67">
                    <div class="subtitle-65">
                        Government expenditure on transport (% of GDP 2019 and
                        2020)
                    </div>
                    <div class="frame-64">
                        <div class="subtitle-66">
                            In 2019, government expenditure in the EU on
                            transport was equivalent to 2.0 % of gross domestic
                            product (GDP). The COVID-19 crisis impacted strongly
                            on government expenditure, as grants, loans and
                            subsidies were provided to support the operation and
                            maintenance of transport systems and facilities. The
                            ratio of government expenditure on transport to GDP
                            was 2.3 % in 2020.
                        </div>
                        <div class="subtitle-66">
                            With the exceptions of Hungary, which recorded a
                            fall in this ratio in 2020, and Ireland and Sweden,
                            which recorded no change, all other EU Member States
                            reported a higher level of government expenditure on
                            transport relative to GDP in 2020 than in 2019. The
                            largest increases, in percentage point terms, were
                            observed in Latvia, Malta, Portugal and Croatia.
                        </div>
                        <div class="subtitle-66">
                            In 2020, Hungary reported the highest level of
                            government expenditure on transport relative to GDP
                            (4.6 %), while Cyprus had the lowest ratio (0.7 %).
                        </div>
                    </div>
                </div>
                <img
                    src="/assets/images/trasp_12.png"
                    loading="lazy"
                    width="1092"
                    height="463"
                    alt=""
                    class="image-8"
                />
                <div class="frame-67">
                    <div class="subtitle-65">
                        Development of government expenditure on transport (% of
                        GDP, EU, 2010–2020)
                    </div>
                    <div class="frame-69">
                        <div class="subtitle-66">
                            In the EU, government expenditure on transport
                            relative to GDP fell from 2.3 % in 2010 to 2.1 % in
                            2012. It remained at 2.1 % or 2.0 % each year until
                            the COVID-19 crisis in 2020 provoked a return to 2.3
                            %.
                        </div>
                        <div class="frame-64">
                            <img
                                src="/assets/images/trasp_13.png"
                                loading="lazy"
                                width="714"
                                height="340"
                                alt=""
                                class="image-8"
                            />
                            <div class="subtitle-67">
                                Source: Eurostat (online data code: gov_10a_exp)
                            </div>
                        </div>
                    </div>
                </div>
                <div class="frame-64">
                    <img
                        src="/assets/images/trasp_14.png"
                        loading="lazy"
                        width="1092"
                        height="734"
                        alt=""
                        class="image-8"
                    />
                    <div class="subtitle-67">
                        Source: Eurostat (online data code: nama_10_an6
                    </div>
                </div>
                <div class="subtitle-66">
                    Investment in transport equipment in the EU ranged from 1.5
                    % to 2.0 % of GDP between 2011 and 2021. Investment fell
                    between 2011 and 2013 during the extended recovery from the
                    global financial and economic crisis a few years earlier.
                    Thereafter, investment in transport equipment relative to
                    GDP increased through to a peak of 2.0 % in 2017, a level
                    that was repeated in 2019. The impact of the COVID-19 crisis
                    on such investment can be clearly seen, with this ratio
                    dropping to 1.7 % in 2020 and 1.6 % in 2021.
                </div>
            </div>


        </div>
    </section>
</template>

<script>
export default {
    name: "TransportAndEconomy",
    props: {
        msg: String,
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/views/home.scss";
</style>
