<template>
    <section class="home-view">

        <!-- Hero banner -->
        <section class="hero-banner">
            <div class="hero-content">
                <div class="inner-wrapper">
                    <header class="hero-header">
                        <h2 class="super-title">Publications</h2>
                        <h1 class="title">Key figures on European transport</h1>
                        <p class="subtitle">2022 edition</p>
                    </header>

                    <article class="hero-info">
                        <div class="info-section">
                            <h3 class="section-title">Editors</h3>
                            <p class="authors">
                                <span>??</span>
                            </p>
                        </div>
                        <div class="info-section">
                            <h3 class="section-title">Layout and graphics</h3>
                            <p class="layout-graphics">
                                <span>Emporij d.o.o.</span>
                                <span class="extra"
                                    >Publications Office of the European Union,
                                    Graphic Design Department</span
                                >
                            </p>
                        </div>

                        <div class="social-share">
                            <p class="share-text">Share publication</p>
                            <div class="share-buttons">
                                <button class="share-button icon-only">
                                    <img
                                        src="/assets/svg/link-alt.svg"
                                        alt="Share on Link"
                                        width="24"
                                        height="24"
                                    />
                                </button>
                                <button class="share-button icon-only">
                                    <img
                                        src="/assets/svg/LinkedIn.svg"
                                        alt="Share on LinkedIn"
                                        width="24"
                                        height="24"
                                    />
                                </button>
                                <button class="share-button icon-only">
                                    <img
                                        src="/assets/svg/twitter.svg"
                                        alt="Share on X"
                                        width="24"
                                        height="24"
                                    />
                                </button>
                                <button class="share-button icon-only">
                                    <img
                                        src="/assets/svg/facebook.svg"
                                        alt="Share on Facebook"
                                        width="24"
                                        height="24"
                                    />
                                </button>
                            </div>
                        </div>
                    </article>

                    <nav class="hero-links">
                        <ul>
                            <li>
                                <a href="#" class="link-item">
                                    <img
                                        src="/assets/svg/plus.svg"
                                        alt="Add to my publications"
                                        width="24"
                                        height="24"
                                    />
                                    Add to my publications</a
                                >
                            </li>
                            <li>
                                <a href="#" class="link-item"
                                    ><img
                                        src="/assets/svg/bell-plus.svg"
                                        alt="Create alert"
                                        width="24"
                                        height="24"
                                    />Create alert</a
                                >
                            </li>
                            <li>
                                <a href="#" class="link-item"
                                    ><img
                                        src="/assets/svg/link.svg"
                                        alt="Permanent link"
                                        width="24"
                                        height="24"
                                    />Permanent link</a
                                >
                            </li>
                            <li>
                                <a href="#" class="link-item"
                                    ><img
                                        src="/assets/svg/purchase-tag-alt.svg"
                                        alt="Metadata RDF"
                                        width="24"
                                        height="24"
                                    />Metadata RDF</a
                                >
                            </li>
                            <li>
                                <a href="#" class="link-item"
                                    ><img
                                        src="/assets/svg/code.svg"
                                        alt="Embed"
                                        width="24"
                                        height="24"
                                    />Embed</a
                                >
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            <figure class="hero-image">
                <img
                    src="/assets/images/example_1.png"
                    role="presentation"
                    alt=""
                    width="654"
                    height="654"
                    loading="lazy"
                />
            </figure>
        </section>

        <!-- Contents -->
        <div class="contents">

            <!-- Text block -->
            <section class="frame-27">
                <div class="subtitle-24">Abstract</div>
                <div class="frame-26">
                    <div class="subtitle-25">
                        <div class="subtitle-25 subtitle-25">
                            <span class="subtitle-27"
                                >Key figures on European transport presents a
                                selection of key transport indicators for the
                                European Union (EU) and its individual Member
                                States, as well as the EFTA countries. This
                                publication may be viewed as an introduction to
                                European transport statistics and provides a
                                starting point for those who wish to explore the
                                wide range of data that are freely available on
                                Eurostat’s website at </span
                            ><span class="subtitle-28"
                                >https://ec.europa.eu/eurostat</span
                            ><span class="subtitle-27">
                                together with a range of online articles in
                                Statistics Explained.
                            </span>
                        </div>
                    </div>
                    <div class="frame-28">
                        <div class="subtitle-26">Editors</div>
                        <div class="frame-29">
                            <div class="subtitle-27">
                                Louise Corselli-Nordblad and Nina Jere
                            </div>
                            <div class="subtitle-27">
                                Eurostat, Unit B4 – Dissemination and user
                                support
                            </div>
                            <div class="subtitle-27">
                                Evangelia Ford-Alexandraki and Georgios Xenellis
                            </div>
                            <div class="subtitle-27">
                                Eurostat, Unit E3 – Transport
                            </div>
                        </div>
                    </div>
                    <div class="frame-29">
                        <div class="subtitle-26">Contact details</div>
                        <div class="frame-29">
                            <div class="subtitle-27">Eurostat</div>
                            <div class="subtitle-27">Bâtiment Joseph Bech</div>
                            <div class="subtitle-27">
                                5, rue Alphonse Weicker
                            </div>
                            <div class="subtitle-27">2721 Luxembourg</div>
                            <div class="subtitle-27">
                                E-mail: estat-user-support@ec.europa.eu
                            </div>
                        </div>
                    </div>
                    <div class="frame-29">
                        <div class="subtitle-26">Design</div>
                        <div class="subtitle-27">
                            Publications Office of the European Union, Graphic
                            Design Department
                        </div>
                    </div>
                    <div class="frame-29">
                        <div class="subtitle-26">Production</div>
                        <div class="subtitle-27">
                            Data processing and drafting of text were done by
                            Giovanni Albertone, Simon Allen and Andrew Redpath –
                            INFORMA s.à r.l.
                        </div>
                    </div>
                    <div class="frame-29">
                        <div class="subtitle-27">
                            For more information please consult
                        </div>
                        <div class="subtitle-27">
                            Eurostat’s website: https://ec.europa.eu/eurostat
                        </div>
                        <div class="subtitle-27">
                            Statistics Explained:
                            https://ec.europa.eu/eurostat/statistics-explained
                        </div>
                    </div>
                    <div class="frame-29">
                        <div class="subtitle-26">Acknowledgements</div>
                        <div class="frame-29">
                            <div class="subtitle-27">
                                The editors of this publication would like to
                                thank colleagues in Eurostat who were involved
                                in its preparation.
                            </div>
                            <div class="subtitle-27">
                                Anna Białas-Motyl, Alain Gallais, Riccardo
                                Gatto, Christine Gerstberger, Mihai-Iulian
                                Gheorghe, Judita Horvathova, Annabelle Jansen,
                                Dorothea Jung, Cristina Martello, Boryana
                                Milusheva, Stephan Moll, Svetoslava Pavlova,
                                Joanna Raczkowska, Iveta Toleikyte and Klaus
                                Volmich
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    </section>
</template>

<script>
export default {
    name: "HomeView",
    props: {
        msg: String,
    }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/views/home.scss";
</style>
